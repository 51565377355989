module.exports = [{
      plugin: require('/home/travis/build/emathgroup/selectedTopics/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/travis/build/emathgroup/selectedTopics/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false},
    },{
      plugin: require('/home/travis/build/emathgroup/selectedTopics/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-155304844-1","head":true,"anonymize":true,"sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('/home/travis/build/emathgroup/selectedTopics/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
