// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-tags-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-img-template-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/templates/img-template.js" /* webpackChunkName: "component---src-templates-img-template-js" */),
  "component---src-templates-img-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/templates/img.js" /* webpackChunkName: "component---src-templates-img-js" */),
  "component---src-pages-404-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-test-js": () => import("/home/travis/build/emathgroup/selectedTopics/src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

